import BasePage from '../../../../../../components/table_page';
import Form from '../form';
import request from '../../../../../../utils/request';

export default {
  name: 'rebate_manage',
  extends: BasePage,
  data() {
    return {
      params: {
        actType: 'rebate',
      },
      budgetState: null,
    };
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('tpm_rebate_activity_list');
    request
      .get('/mdm/mdmParameterController/getParameterValue', {
        parameterCode: 'budget_use_date_rule',
      })
      .then((res) => {
        this.budgetState = res.result;
      });
  },
  methods: {
    clickVisible({ row, val: { code } }) {
      console.log(code);
    },
    modalClick({ val, row }) {
      const { code } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code,
          row,
          budgetState: this.budgetState,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };
      this.$emit('approve', configParams);
    },
  },
};
